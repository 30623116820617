import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { FontContext } from './FontContext';
import { deleteSessionCookie } from '../utils/cookieManager'; // Import for session cookie management
import 'firebase/compat/auth';
import { auth } from '../firebase'; // Adjust the path as necessary


type MenuProps = {
  toggleMenu: () => void;
  setShowAbout: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTricks: React.Dispatch<React.SetStateAction<boolean>>;
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
};

const Menu: React.FC<MenuProps> = ({ toggleMenu, setShowAbout, setShowTricks, setShowProfile, setShowLogin }) => {
  const [rightPosition, setRightPosition] = useState('-100%');
  const fontContext = useContext(FontContext); // Use FontContext
  const authContext = useContext(AuthContext); // Use AuthContext
  const isAuthenticated = authContext?.user !== null;

    // Function to handle user logout
    const handleLogout = () => {
      auth.signOut().then(() => {
        deleteSessionCookie(); // Delete the session cookie upon successful logout
        toggleMenu(); // Close the menu after logout
      });
    };

  useEffect(() => {
    setRightPosition('0');
  }, []);

  const handleLoginClick = () => {
    if (!isAuthenticated) {
      setShowLogin(true);
    }
    toggleMenu();
  };

  const handleClose = () => {
    setRightPosition('-100%');
    setTimeout(toggleMenu, 300); 
  };

  const handleAboutClick = () => {
    setShowAbout(true);
    toggleMenu();
  };

  const handleTricksClick = () => {
    setShowTricks(true);
    toggleMenu();
  };

  const handleProfileClick = () => {
    setShowProfile(true);
    toggleMenu();
  };

    return (
      <div 
        style={{
          width: '90%', 
          right: rightPosition, 
          backgroundColor: 'rgba(211, 211, 211, 0.9)', 
          zIndex: 101,
        }} 
        className={`fixed top-0 right-0 h-full transition-all duration-300 ease-in-out text-3xl ${fontContext?.currentFont}`} // Use currentFont from FontContext
      >
        <div className="px-6 py-8 flex flex-col items-center justify-center">
          <button onClick={handleClose} className="self-start mb-8">
            X {/* Replace this with your SVG or icon */}
          </button>
          <a href="/home" className="block text-center my-8">Home</a>
          <div className="border-b-2 border-gray-400 w-full my-8"></div>
          <a onClick={handleAboutClick} className="block ml-6 cursor-pointer">About</a>
          <div className="border-b-2 border-gray-400 w-full my-8"></div>
          <a onClick={handleTricksClick} className="block ml-6 cursor-pointer">Tricks</a>
          <div className="border-b-2 border-gray-400 w-full my-8"></div>
          <a onClick={handleProfileClick} className="block ml-6 cursor-pointer">Profile</a>
          <div className="border-b-2 border-gray-400 w-full my-8"></div>
          {!isAuthenticated && (
          <button onClick={handleLoginClick} className="block text-center my-8">Login</button>
        )}
        {isAuthenticated && (
          <button onClick={handleLogout} className="block text-center my-8">Logout</button>
        )}
        </div>
      </div>
    );
  };

  export default Menu;