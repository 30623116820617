// cookieManager.ts
import Cookies from 'js-cookie';

const COOKIE_NAME = 'sessionToken';

export const setSessionCookie = (sessionToken: string, expiresInDays: number = 1) => {
    const isSecure = process.env.NODE_ENV === 'production'; // true if in production, else false
    Cookies.set(COOKIE_NAME, sessionToken, { expires: expiresInDays, secure: isSecure, sameSite: 'Strict' });
};

export const getSessionCookie = (): string | undefined => {
    return Cookies.get(COOKIE_NAME);
};

export const deleteSessionCookie = () => {
    Cookies.remove(COOKIE_NAME);
};
export {};